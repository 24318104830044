<template>
    <v-select v-model="selectedStatuses" :items="appointmentStatuses" :disabled="disabled" item-value="id" clear-icon="far fa-times-circle" @focus="$emit('focus')" :menu-props="{ bottom: true, offsetY: true }" @change="$emit('input', selectedStatuses)" :multiple="multiple" :label="label" :placeholder="placeholder" :error-messages="errors" :hide-details="!failed" :class="{ 'flex-grow-0': flexGrow0 }" color="app-blue" background-color="white" no-filter outlined clearable dense>
        <template v-slot:selection="data">
            <v-chip v-if="data.index === 0" :color="data.item.color" small label dark>
                {{ data.item.label }}
            </v-chip>

            <span v-if="data.index === 1" class="grey--text text-caption">
                (+{{ selectedStatuses.length - 1 }} autres)
            </span>
        </template>

        <template v-slot:prepend>
            <slot name="prepend" />
        </template>

        <template v-slot:item="{ item }">
            <v-list-item-content >
                <v-list-item-title>
                    <v-chip :color="item.color" label small dark>
                        {{ item.label }}
                    </v-chip>
                </v-list-item-title>
            </v-list-item-content>
        </template>
    </v-select>
</template>

<script>
import appointmentsMixin from '../../mixins/appointments';

export default {
    name: 'AppointmentStatusAutocomplete',

    mixins: [appointmentsMixin],

    props: {
        value: { required: true },
        label: { type: String },
        placeholder: { type: String },
        disabled: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false },
        flexGrow0: { type: Boolean, default: false },
        noDynamicSearch: { type: Boolean, default: false },
        errors: { type: Array },
        failed: { type: Boolean, default: false }
    },

    data: () => ({
        selectedStatuses: null
    }),

    watch: {
        value() {
            this.selectedStatuses = this.value;
        }
    },

    created() {
        this.selectedStatuses = this.value;
    }
};
</script>