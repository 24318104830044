<template>
    <v-dialog v-model="isOpen" width="500px">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Renvoyer le mail de connexion
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider class="mb-4" />

            <v-card-text>
                <v-simple-table>
                    <tbody>
                        <tr v-for="buyer of sale.buyers"  :key="buyer.id + '_' + buyer.type">
                            <td>
                                {{ buyer.name }} {{ buyer.firstname }}
                            </td>

                            <td>
                                <v-tooltip v-if="buyer.extranet.open" top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip class="my-1 mr-2" color="green lighten-2" small label dark v-on="on"> Ouvert </v-chip>
                                    </template>
                                    Accès ouvert le {{ buyer.extranet.openDate | toDate() }} 
                                    <template v-if="buyer.extranet.lastSignUpNotificationDate">
                                        <br>
                                        Dernier envoi le {{ buyer.extranet.lastSignUpNotificationDate | toDate() }} <br>
                                        {{ buyer.extranet.signUpNotificationCount }} {{ buyer.extranet.signUpNotificationCount | plural('envoi', 'envois') }} au total
                                    </template>
                                </v-tooltip>

                                <v-tooltip v-if="buyer.extranet.connected" top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip class="my-1" color="green lighten-2" small label dark v-on="on"> Connecté </v-chip>
                                    </template>
                                    Première connexion le {{ buyer.extranet.firstLoginDate | toDate() }} <br>
                                    Dernière connexion le {{ buyer.extranet.lastLoginDate | toDate() }} <br>
                                    {{ buyer.extranet.connectionsCount }} connexions au total
                                </v-tooltip>
                                <v-chip v-else class="my-1" color="red lighten-2" small label dark> Non connecté </v-chip>
                            </td>

                            <td>
                                <ConfirmationDialog @confirm="sendSignUpNotification(buyer)" text="Souhaitez-vous renvoyer le mail d'invitation à cet acquéreur ?">
                                    <template v-slot:activator="{ on: tooltip }">
                                        <v-tooltip v-if="sale.extranet.open" top>
                                            <template v-slot:activator="{ on: dialog }">
                                                <v-btn icon v-on="{ ...dialog, ...tooltip }">
                                                    <v-icon>mdi-send</v-icon>
                                                </v-btn>
                                            </template>
                                            Renvoyer le mail de connexion
                                        </v-tooltip>
                                    </template>
                                </ConfirmationDialog>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-spacer />

                <v-btn @click="isOpen = false" small> Fermer </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ConfirmationDialog from '../../dialogs/ConfirmationDialog.vue';

export default {
    name: 'ResendSignupNotification',

    components: {
        ConfirmationDialog
    },

    props: {
        sale: { type: Object, required: true }
    },

    data: () => ({
        isOpen: false
    }),

    methods: {
        async sendSignUpNotification(buyer) {
            try {
                this.setLoading(true);

                const email = buyer.email;

                const { success, err } = await this.repos.contacts.sendSignUpNotification({ email });
                if (!success || err) {
                    throw new Error(err);
                }
                this.$notify({
                    title: 'Information',
                    text: 'Le mail a bien été envoyé',
                    type: 'success'
                });
                // on attend le prochain tick pour ne pas declancher le setLoading false trop tôt
                this.$nextTick(() => {
                    this.$emit('signUpSent');
                });
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du l\'envoi du mail de connexion',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    }
};
</script>