<template>
    <v-dialog v-model="isOpen" width="1200">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Créer une disponibilité
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider class="mb-4" />

            <v-card-text>
                <ValidationObserver ref="observer">
                    <table style="border-spacing: 10px;">
                        <tr>
                            <td>
                                Quel intervenant
                            </td>

                            <td>
                                <AppointmentStakeholderAutocomplete ref="appointmentStakeholderAutocomplete" v-model="disponibility.stakeholderIds" noDynamicSearch />
                            </td>
                        </tr>
                    </table>
                </ValidationObserver>

                <table class="w-100">
                    <thead>
                        <th v-for="(day, i) of weekDays" :key="i" class="text-capitalize">
                            {{ day.date | toDay() }}
                        </th>
                    </thead>

                    <tbody>
                        <tr>
                            <td v-for="(day, i) of weekDays" :key="i" style="width: calc(100%/7)" class="text-center">
                                <v-menu v-model="day.isMenuOpen" :close-on-content-click="false">
                                    <template v-slot:activator="{ on }">
                                        <v-btn color="primary" x-small v-on="on">
                                            <v-icon x-small> fas fa-plus </v-icon>
                                        </v-btn>
                                    </template>

                                    <v-card>
                                        <v-card-text class="d-flex">
                                            <v-select v-model="day.startHours" :items="selectHours" class="mr-2" label="de" style="width: 100px;" hide-details outlined dense />
                                            <v-select v-model="day.endHours" :items="selectHours" label="à" style="width: 100px;" hide-details outlined dense />
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-btn @click="addDisponibility(day)" color="primary" x-small depressed>
                                                Ajouter
                                            </v-btn>

                                            <v-btn @click="cancelDisponibility(day)" x-small depressed>
                                                Annuler
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>
                            </td>
                        </tr>

                        <tr>
                            <td v-for="(day, i) of weekDays" :key="i" class="text-center">
                                <div v-for="(disponibility, i) of day.disponibilities" :key="i" class="d-flex align-center">
                                    De {{ disponibility.startHours }} à {{ disponibility.endHours }}

                                    <v-btn @click="removeDisponibility(day, i)" color="red" icon>
                                        <v-icon x-small> fas fa-times </v-icon>
                                    </v-btn>
                                    <!-- <v-select label="Heure" class="mr-2" hide-details outlined dense />
                                    <v-select label="Minute" hide-details outlined dense /> -->
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit()" class="mr-2" color="primary" small> Enregistrer </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small> Annuler </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { addHours, addMinutes, format, startOfDay, startOfWeek, addDays } from 'date-fns';
import AppointmentStakeholderAutocomplete from '../widgets/AppointmentStakeholderAutocomplete.vue';

export default {
    name: 'CreateDisponibilityDialog',

    components: {
        AppointmentStakeholderAutocomplete
    },

    data: () => ({
        day: new Date(),

        isOpen: false,
        disponibility: {},
        additionalDataFetched: false,

        startDateHours: null,
        endDateHours: null
    }),

    computed: {
        selectHours() {
            const hours = [];

            let startHour = 6;
            let endHour = 20;

            for (let i = startHour; i <= endHour; i++) {
                hours.push(i + ':00');
                hours.push(i + ':30');
                // hours.push({ label: i + 'h30', value: i + ':30' });
            }

            return hours;
        },

        weekDays() {
            const now = new Date();
            const startOfWeekDate = startOfWeek(now, { weekStartsOn: 1 });
            // const endOfWeekDate = endOfWeek(now, { weekStartsOn: 1 });
            const weekDays = [];
            for(let i = 0; i < 7; i++) {
                const day = addDays(startOfWeekDate, i);
                weekDays.push({
                    date: day,
                    isMenuOpen: false,
                    newHours: '',
                    newMinutes: '',
                    disponibilities: []
                });
            }

            return weekDays;
        }
    },

    watch: {
        isOpen() {
            if (this.isOpen) {
                this.$nextTick(() => {
                    if (!this.additionalDataFetched) {
                        this.additionalDataFetched = true;
                        this.$refs.appointmentStakeholderAutocomplete.fetchStakeholders({ limit: 10000 });
                    }
                    this.$refs.observer.reset();
                });
            }
        }
    },

    methods: {
        open(disponibility, stakeholderIds, day) {
            if (day) {
                this.day = startOfDay(new Date(day));
            } else {
                this.day = new Date();
            }

            this.disponibility = {};
            this.startDateHours = null;
            this.endDateHours = null;
            if (disponibility) {
                this.disponibility = disponibility;
                if (this.disponibility.stakeholder) {
                    this.disponibility.stakeholderIds = [this.disponibility.stakeholder.id];
                }
            }
            if (stakeholderIds) {
                this.disponibility.stakeholderIds = stakeholderIds;
            }

            if (this.disponibility.startDate) {
                this.startDateHours = format(new Date(this.disponibility.startDate), 'HH:mm');
                this.day = startOfDay(new Date(this.disponibility.startDate));
            }

            if (this.disponibility.endDate) {
                this.endDateHours = format(new Date(this.disponibility.endDate), 'HH:mm');
            }

            this.isOpen = true;
        },

        async submitCreation() {
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                return;
            }

            try {
                this.setLoading(true);

                const body = { disponibility: this.disponibility };

                const { success, err } = await this.repos.appointments.createDisponibility(body);
                if (success) {
                    this.isOpen = false;
                    this.$emit('created');
                    this.$notify({
                        title: 'Information',
                        text: 'La disponibilité a bien été créée',
                        type: 'success'
                    });
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la modification d\'une disponibilité',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async submitEdition() {
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                return;
            }

            try {
                this.setLoading(true);

                const body = { disponibility: this.disponibility };

                const { success, err } = await this.repos.appointments.updateDisponibility(this.disponibility.id, body);
                if (success) {
                    this.isOpen = false;
                    this.$emit('updated');
                    this.$notify({
                        title: 'Information',
                        text: 'La disponibilité a bien été mis à jour',
                        type: 'success'
                    });
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la création d\'une disponibilité',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async remove() {
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                return;
            }

            try {
                this.setLoading(true);

                const { success, err } = await this.repos.appointments.deleteDisponibility(this.disponibility.id);
                if (success) {
                    this.isOpen = false;
                    this.$emit('updated');
                    this.$emit('removed');
                    this.$notify({
                        title: 'Information',
                        text: 'La disponibilité a bien été supprimée',
                        type: 'success'
                    });
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la suppression de la disponibilité',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async submit() {
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                return;
            }

            let [hours, minutes] = this.startDateHours.split(':');
            hours = parseInt(hours);
            minutes = parseInt(minutes);
            let startDate = this.day;

            startDate = addHours(startDate, hours);
            startDate = addMinutes(startDate, minutes);

            [hours, minutes] = this.endDateHours.split(':');
            hours = parseInt(hours);
            minutes = parseInt(minutes);
            let endDate = this.day;

            endDate = addHours(endDate, hours);
            endDate = addMinutes(endDate, minutes);

            this.disponibility.startDate = startDate;
            this.disponibility.endDate = endDate;

            await this.submitCreation();
        },

        addDisponibility(day) {
            day.disponibilities.push({ 
                startHours: day.startHours,
                endHours: day.endHours
            });
            day.isMenuOpen = false;
            day.startHours = undefined;
            day.endHours = undefined;
            this.$forceUpdate();
        },

        cancelDisponibility(day) {
            day.isMenuOpen = false;
            day.startHours = undefined;
            day.endHours = undefined;
            this.$forceUpdate();
        },

        removeDisponibility(day, index) {
            day.disponibilities.splice(index, 1);
            this.$forceUpdate();
        },

        init() {
            
        }
    },

    created() {
        this.init();
    }
};
</script>
