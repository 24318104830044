<template>
    <div class="d-flex align-center">
        <span class="mr-2">
            {{ title }}
        </span>

        <div class="d-inline-flex flex-column">
            <v-icon small @click="$emit('input', asc)" :color="value === asc ? 'app-blue' : null" style="margin-bottom: -8px"> fas fa-caret-up </v-icon>
            <v-icon small @click="$emit('input', desc)" :color="value === desc ? 'app-blue' : null"> fas fa-caret-down </v-icon>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SortArrows',

    props: {
        value: { type: String, required: true },
        title: { type: String, required: true },
        asc: { type: String, required: true },
        desc: { type: String, required: true }
    }
};
</script>